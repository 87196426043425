/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

.rdw-editor-wrapper .rdw-right-aligned-block > .public-DraftStyleDefault-ltr {
  text-align: right!important;
}

.rdw-editor-wrapper .rdw-center-aligned-block > .public-DraftStyleDefault-ltr {
  text-align: center!important;
}

.rdw-editor-wrapper .rdw-justify-aligned-block > .public-DraftStyleDefault-ltr {
  text-align: justify!important;
}
